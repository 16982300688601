import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, take} from 'rxjs';
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class IsAuthedGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise<boolean|UrlTree>(resolve=>{
      this.auth.$user.pipe(take(1)).subscribe(next=>{
        resolve(true);
        if(!next) {
          resolve(this.router.createUrlTree(['/login']));
        }else{
          resolve(true);
        }
      });

    });
  }

}
