import { Injectable } from '@angular/core';
import {BehaviorSubject, map} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {BasicResponse} from "./base-response";

export class UserModel {
  id?: number;
  name?: string;
  role?: string;
  houses?: any;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public $user: BehaviorSubject<UserModel|null> = new BehaviorSubject<UserModel|null>(null);

  constructor(private http: HttpClient) { }

  aboutme() {
    return this.http.get<BasicResponse<UserModel>>("/front/auth").pipe(map(i=>{ this.$user.next(i.data??null); return i;}))
  }
}
