import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthFormComponent} from "./auth-form/auth-form.component";
import {IsAuthedGuard} from "./is-authed.guard";

const routes: Routes = [
  {path:'',loadChildren:()=>import('./panel/panel.module').then(m=>m.PanelModule),canActivate:[IsAuthedGuard]},


  {path:'login',component:AuthFormComponent}
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
